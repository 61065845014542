import React, { useState } from 'react';
import { Button, showErrorAlert } from 'components/alf-design';
import { useApi, usePolicy, submitFormData } from 'utils';

const ManagePaymentButton = ({ fullWidth, style, setup }) => {
  const api = useApi();
  const { selectedPolicy } = usePolicy();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const { data } = await api.withAuth.getPaymentMethod(
        selectedPolicy.policyNumber
      );

      submitFormData(data.data.url, data.data.formData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      let description;
      if (error.response) {
        description = error.response.data.error;
      } else if (error.request) {
        description =
          'Network error. Please check your connection and try again.';
      } else {
        description = `Error attempting to request payment details.`;
      }
      showErrorAlert({
        message: 'Manage Payment Error',
        description
      });
    }
  };

  return (
    <>
      <Button
        type="primary"
        id="manage-payment-button"
        style={style}
        onClick={handleClick}
        fullWidth={fullWidth}
        withLoading={loading}
      >
        {setup ? 'Set Up Auto Pay' : 'Manage Payment'}
      </Button>
    </>
  );
};

export default ManagePaymentButton;
