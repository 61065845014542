/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useResponsive } from 'utils';
import config from 'config';

import { arch } from '../../assets/img';
import './Background.css';
import Banner from './Banner';

const Background = ({ isMobile, children }) => {
  const { screenIsDown } = useResponsive();

  const style = {
    backgroundImage: null
  };
  if (isMobile) {
    style.backgroundImage = null;
    style.backgroundColor = 'transparent';
  } else if (!screenIsDown('lg')) {
    style.backgroundSize = 'contain';
  } else {
    style.backgroundSize = '200% 200%';
  }

  return (
    <div style={style} className="container">
      {config?.banner?.display === 'true' && <Banner />}
      {children}
    </div>
  );
};

Background.propTypes = {
  isMobile: PropTypes.bool,
  children: PropTypes.any
};

export default Background;
