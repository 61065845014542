import React, { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';

import {
  policiesMock,
  claimsMock,
  paymentsMock,
  documentsMock
} from './mockData';
import config from '../config';
import useFlags from './useFlags';
import { useAuth0 } from './auth0';

const api = axios.create({ baseURL: config.apiUrl });

const artificialDelay = (data) =>
  new Promise((resolve) => {
    setTimeout(() => resolve({ data }), 400);
  });

export const useApi = () => {
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const { mockPolicies, mockClaims, mockPayments } = useFlags();

  useEffect(() => {
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        if (!!response && response.status === 401) {
          loginWithRedirect();
        }
        return Promise.reject(error);
      }
    );
  }, [loginWithRedirect]);

  const getHeaders = useCallback(
    async (authenticated, apiConfig = {}) => {
      const headers = { ...apiConfig.headers };
      if (authenticated) {
        const token = await getTokenSilently();

        if (!token) {
          loginWithRedirect();
        }
        headers.Authorization = `Bearer ${token.__raw}`;
      }
      return headers;
    },
    [getTokenSilently, loginWithRedirect]
  );

  const getUrl = (url, authenticated) =>
    authenticated ? `/secure${url}` : url;

  const apiFactory = useCallback(
    (authenticated) => {
      const get = async (path, apiConfig) => {
        const url = getUrl(path, authenticated);
        const headers = await getHeaders(authenticated, apiConfig);
        return api.get(url, { ...apiConfig, headers });
      };

      return {
        getClaims: (policyNumber, apiConfig = {}) => {
          if (mockClaims) {
            const claims = claimsMock.filter(
              (c) => c.policyNumber === policyNumber
            );
            return artificialDelay(claims);
          }

          return get(`/claims?policy_number=${policyNumber}`, apiConfig);
        },
        getPayments: (policyNumber, apiConfig = {}) => {
          if (mockPayments) {
            const payments = paymentsMock.filter(
              (p) => p.policyNumber === policyNumber
            );
            return artificialDelay({ payments });
          }

          return get(`/policies/${policyNumber}/payments`, apiConfig);
        },
        getPaymentMethod: (policyNumber, apiConfig = {}) => {
          if (mockPayments) {
            return artificialDelay({
              data: {
                key: {
                  der: {},
                  jwk: {}
                }
              }
            });
          }

          return get(
            `/policies/${policyNumber}/payment-method?targetOrigin=${window.location.origin}&redirectUrl=${window.location.origin}`,
            apiConfig
          );
        },
        getPolicy: (policyNumber, apiConfig = {}) => {
          if (mockPolicies) {
            const policy = policiesMock.find(
              (x) => x.policyNumber === policyNumber
            );
            return artificialDelay(policy);
          }

          return get(`/policies/${policyNumber}`, apiConfig);
        },
        getAllPolicies: (apiConfig = {}) => {
          if (mockPolicies) {
            return artificialDelay(policiesMock);
          }

          return get('/policies', apiConfig);
        },
        getPolicyDeclaration: async (policyNumber, apiConfig) => {
          if (mockPolicies) {
            const documents = documentsMock;
            return artificialDelay(documents);
          }

          const headers = await getHeaders(authenticated, apiConfig);
          return api.get(
            getUrl(`/policies/${policyNumber}/attachments`, authenticated),
            { ...apiConfig, headers }
          );
        },
        getPolicyDocument: async (policyNumber, policyDocument, apiConfig) => {
          const configAttach = {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf'
            }
          };
          const headersAttach = await getHeaders(authenticated, configAttach);

          const file = await api.get(
            getUrl(
              `/policies/${policyNumber}/attachments/${policyDocument.id}?source=${policyDocument.source}`,
              authenticated
            ),
            { headers: { ...headersAttach }, responseType: 'blob' }
          );
          const blob = new Blob([file.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${policyDocument.title}.pdf`;
          link.click();
        },
        post: async (url, body, apiConfig) => {
          const headers = await getHeaders(authenticated, apiConfig);
          return api.post(getUrl(url, authenticated), body, {
            ...apiConfig,
            headers
          });
        },
        patch: async (url, body, apiConfig) => {
          const headers = await getHeaders(authenticated, apiConfig);
          return api.patch(getUrl(url, authenticated), body, {
            ...apiConfig,
            headers
          });
        }
      };
    },
    [getHeaders, mockClaims, mockPayments, mockPolicies]
  );

  const ctx = useMemo(
    () => ({
      withAuth: apiFactory(true),
      ...apiFactory(false)
    }),
    [apiFactory]
  );

  return ctx;
};

export default function withApi(WrappedComponent) {
  return (props) => {
    const _api = useApi();
    return <WrappedComponent api={_api} {...props} />;
  };
}
