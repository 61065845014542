import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Col, Button, Icon } from 'components/alf-design';
import { formats, customTypes, useResponsive, useFlags, useAuth0 } from 'utils';
import { STATUS, METHOD } from 'utils/constants';

import BillingSubtitles from './BillingSubtitles';
import ManagePaymentButton from './ManagePaymentButton';

const InsuredPaid = ({
  agent,
  billing,
  showCancellationData,
  handleMakePaymentClick,
  handleHistoryClick,
  handleManagePaymentClick,
  disableMakePayments,
  policyStatus
}) => {
  const { width } = useResponsive();
  const { enableManageAutopay, enableAutopaySetup } = useFlags();
  const normalWidth = width > 1550 || (width < 1280 && width > 768);
  const phoneWidth = width <= 768;
  const showCurrentAmountDue =
    !billing.autoPay &&
    (!billing.pastDueAmount || billing.currentDueAmount) &&
    (billing.collectionMethod !== METHOD.PENDING_CARD &&
      billing.collectionMethod !== METHOD.PENDING_BANK);
  const { user } = useAuth0();
  const isAdmin = user
    ? user.email.includes('@slideinsurance.com') &&
      user.role &&
      user.role?.isAdmin
    : false;
  return (
    <>
      <Row>
        <Col>
          {policyStatus === STATUS.PENDING_CANCEL && (
            <Typography
              type="caption1"
              component="span"
              style={{
                display: 'inline-block',
                color: '#DC2A2A'
              }}
            >
              This policy is pending cancellation. For billing information,
              please contact your agent.
            </Typography>
          )}
          {billing.autoPay && (
            <Typography
              type="caption1"
              component="span"
              style={{
                display: 'inline-block'
              }}
            >
              Your policy is set up as Auto Pay. For questions or assistance
              regarding your payments, please contact your agent.
            </Typography>
          )}
          {(billing.collectionMethod === METHOD.PENDING_CARD ||
            billing.collectionMethod === METHOD.PENDING_BANK) && (
            <div
              style={{
                display: !normalWidth ? 'block' : 'flex',
                justifyContent: 'space-around',
                marginTop: 20
              }}
            >
              <Col
                md={
                  normalWidth && enableManageAutopay && enableAutopaySetup
                    ? 14
                    : 24
                }
                style={{
                  paddingRight:
                    !normalWidth || !enableManageAutopay || !enableAutopaySetup
                      ? 0
                      : 50,
                  marginBottom: !normalWidth ? 20 : 0
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon
                    name="exclamationCircle"
                    fontSize="18"
                    color="#b8860d"
                    style={{ minWidth: 18 }}
                  />
                  <Typography
                    type="caption1"
                    component="span"
                    style={{
                      color: '#b8860d',
                      marginLeft: 25
                    }}
                  >
                    {enableManageAutopay &&
                    enableAutopaySetup &&
                    billing.autoPay
                      ? 'Additional information is required in order for this policy to use Auto Pay.'
                      : 'Additional information is required in order for this policy to use Auto Pay. Please contact your agent to complete setup.'}
                  </Typography>
                </div>
              </Col>
              {enableManageAutopay && enableAutopaySetup && billing.autoPay && (
                <Col md={normalWidth ? 10 : 24}>
                  <ManagePaymentButton
                    style={{ marginTop: 5, width: '100%' }}
                    fullWidth={!normalWidth}
                    setup
                  />
                </Col>
              )}
            </div>
          )}
          {!billing.autoPay && disableMakePayments && (
            <Typography
              type="caption1"
              component="span"
              style={{
                display: 'inline-block'
              }}
            >
              For questions or assistance regarding your payments, please
              contact your agent.
            </Typography>
          )}
          {billing.pastDueAmount &&
            billing.collectionMethod !== METHOD.PENDING_CARD &&
            billing.collectionMethod !== METHOD.PENDING_BANK && (
              <Typography
                type="caption1"
                component="span"
                style={{
                  display: 'inline-block',
                  color: '#DC2A2A',
                  marginTop: 10
                }}
              >
                Your last installment was not paid in full.
              </Typography>
            )}
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent:
            billing.pastDueAmount && showCurrentAmountDue
              ? 'space-between'
              : 'space-around',
          marginTop: 20
        }}
      >
        {billing.pastDueAmount &&
        billing.collectionMethod !== METHOD.PENDING_CARD &&
        billing.collectionMethod !== METHOD.PENDING_BANK ? (
          <Col
            md={
              showCurrentAmountDue && billing.currentDueDate
                ? 8
                : showCurrentAmountDue
                ? 14
                : 24
            }
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              textAlign:
                showCurrentAmountDue && !billing.currentDueDate
                  ? 'left'
                  : 'center'
            }}
          >
            <Typography
              type="subtitle"
              style={{
                fontSize: 'min(18rem, calc(18px * 1.5))'
              }}
            >
              Past due
            </Typography>
            <Typography
              type="heading3"
              component="h3"
              style={{
                color: '#DC2A2A',
                fontSize: phoneWidth && 'min(16rem, calc(16px * 1.5))'
              }}
            >
              {formats.moneyFormat(billing.pastDueAmount)}
            </Typography>
          </Col>
        ) : null}

        {showCurrentAmountDue &&
        (billing.currentDueAmount > 0 || !billing.pastDueAmount) ? (
          <Col
            md={billing.currentDueDate ? 8 : 24}
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              textAlign:
                billing.pastDueAmount && !billing.currentDueDate
                  ? 'left'
                  : 'center'
            }}
          >
            <Typography
              type="subtitle"
              style={{ fontSize: 'min(18rem, calc(18px * 1.5))' }}
            >
              Amount due
            </Typography>
            <Typography
              type="heading3"
              component="h3"
              style={{ fontSize: phoneWidth && 'min(16rem, calc(16px * 1.5))' }}
            >
              {formats.moneyFormat(billing.currentDueAmount)}
            </Typography>
          </Col>
        ) : null}

        {billing.currentDueAmount &&
        showCurrentAmountDue &&
        billing.currentDueDate ? (
          <Col
            md={8}
            style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'center' }}
          >
            <Typography
              type="subtitle"
              style={{ fontSize: 'min(18rem, calc(18px * 1.5))' }}
            >
              Due date
            </Typography>
            <Typography
              type="heading3"
              component="h3"
              style={{ fontSize: phoneWidth && 'min(16rem, calc(16px * 1.5))' }}
            >
              {formats.dateFormat(billing.currentDueDate)}
            </Typography>
          </Col>
        ) : null}

        {showCancellationData && (
          <span style={{ display: 'inline-block', textAlign: 'left' }}>
            <Typography
              type="subtitle"
              style={{ fontSize: 'min(18rem, calc(18px * 1.5))' }}
            >
              Cancellation Date
            </Typography>
            <Typography
              type="heading3"
              component="h3"
              style={{ color: '#DC2A2A' }}
            >
              {billing.cancellationDate}
            </Typography>
          </span>
        )}
      </div>
      <hr />
      <Row gutter={24} className="billing-content" style={{ marginTop: 15 }}>
        <div>
          {showCancellationData && (
            <div className="alf-policy-row vertical billing-cancellation">
              <Typography
                type="caption1"
                component="span"
                style={{ color: '#DC2A2A' }}
              >
                <b>Cancellation Date </b>
              </Typography>

              <Typography
                type="caption1"
                component="span"
                style={{ color: '#DC2A2A' }}
              >
                {billing.cancellationDate}
              </Typography>
            </div>
          )}
          <BillingSubtitles agent={agent} billing={billing} />
        </div>
      </Row>
      {billing.collectionMethod !== METHOD.PENDING_CARD &&
        billing.collectionMethod !== METHOD.PENDING_BANK && (
          <div
            style={{
              display: 'flex',
              flexDirection: normalWidth ? 'row' : 'column',
              justifyContent: 'flex-start',
              marginTop: 16
            }}
          >
            {billing.autoPay && enableManageAutopay && (
              <Col
                md={normalWidth ? 14 : 24}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <ManagePaymentButton
                  style={{ minWidth: 200 }}
                  fullWidth={!normalWidth}
                />
              </Col>
            )}
            {!disableMakePayments && !billing.autoPay && (
              <Col
                md={normalWidth ? 14 : 24}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <Button
                  type="primary"
                  id="payments-button"
                  onClick={handleMakePaymentClick}
                  fullWidth={!normalWidth}
                  disabled={isAdmin}
                >
                  Make a Payment
                </Button>
              </Col>
            )}
            <div id="portalOneContainer"></div>
            {billing.collectionMethod !== METHOD.PENDING_CARD &&
              billing.collectionMethod !== METHOD.PENDING_BANK && (
                <Col
                  md={normalWidth ? 10 : 24}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <Button
                    id="payment-history-button"
                    onClick={handleHistoryClick}
                    fullWidth={!normalWidth}
                    style={!normalWidth ? { marginTop: 10 } : null}
                  >
                    Payment History
                  </Button>
                </Col>
              )}
          </div>
        )}
    </>
  );
};
InsuredPaid.propTypes = {
  agent: customTypes.agent,
  billing: customTypes.billing,
  cancellationDate: PropTypes.string,
  showCancellationData: PropTypes.bool,
  handleMakePaymentClick: PropTypes.func,
  handleHistoryClick: PropTypes.func,
  handleManagePaymentClick: PropTypes.func,
  disableMakePayments: PropTypes.bool
};

export default InsuredPaid;
