import React, { useCallback, useState, useEffect } from 'react';
import queryString from 'query-string';
import { usePolicy } from 'utils';
import formats from 'utils/formats';

import { Typography, ExpansionPanel, Link, Spinner } from './alf-design';

const styles = {
  container: {
    maxWidth: 800,
    paddingTop: 30,
    margin: '0 auto'
  },
  category: {
    marginTop: 45,
    marginBottom: 15
  }
};

const Help = ({ history, location }) => {
  const [queryParams, setQueryParams] = useState(null);
  const onError = useCallback(
    (err, entity) => {
      if (entity !== 'claims') {
        history.replace('/');
      }
    },
    [history]
  );
  const { policies, selectedPolicy, loading } = usePolicy(
    queryParams ? queryParams.policy : null,
    {
      onError,
      loadPolicies: true
    }
  );

  useEffect(() => {
    setQueryParams(queryString.parse(location.search));
    document.title = 'FAQs | Slide Insurance';
  }, [location.search]);

  const policy = selectedPolicy || {};

  const agencyData = (showAddress, noMargin = false) => {
    if (policy.agent) {
      const agency = {
        name: policy.agent.organizationName,
        phoneNumber: formats.phoneFormat(policy.agent.phone),
        address: `${policy.agent.street}, ${policy.agent.city}, ${
          policy.agent.state
        } ${formats.zipFormat(policy.agent.zip)}`,
        email: policy.agent.email
      };
      return (
        <div style={{ marginTop: noMargin ? 0 : 12 }}>
          <b>{agency.name}</b>
          {showAddress && (
            <>
              <br />
              <span>{agency.address}</span>
            </>
          )}
          <br />
          {agency.email && (
            <Link type="primary" href={`mailto:${agency.email}`}>
              {agency.email}
            </Link>
          )}
          <br />
          {agency.phoneNumber && (
            <Link type="primary" href={`tel:${agency.phoneNumber}`}>
              {agency.phoneNumber}
            </Link>
          )}
        </div>
      );
    }
    return null;
  };

  const customerCareNumber = '(800) 748-2030';
  const customerCare = (
    <div style={{ marginTop: 12 }}>
      <b>Customer Service</b>
      <br />
      <Link type="primary" href={`tel:${customerCareNumber}`}>
        {customerCareNumber}
      </Link>
    </div>
  );

  const addUrl = `${window.origin}/add-policy`;

  let dataPolicies = [
    {
      isCategory: true,
      title: 'Policy'
    },
    {
      question: 'Who do I contact for assistance with my policy?',
      answer: (
        <div id="help-collapse-text0">
          <Typography type="paragraph" component="span">
            You can contact your insurance agent:
            {agencyData()}
          </Typography>
          <br />
          <Typography type="paragraph" component="span">
            You can also contact Slide Customer Care:
            {customerCare}
          </Typography>
        </div>
      )
    },
    {
      question: 'How do I change my contact information?',
      answer: (
        <Typography id="help-collapse-text1" type="paragraph" component="span">
          You can update your contact information on your policy by contacting
          your agency:
          {agencyData()}
        </Typography>
      )
    },
    {
      question:
        'How do I change the coverages and/or deductibles on my policy?',
      answer: (
        <Typography id="help-collapse-text2" type="paragraph" component="span">
          To make coverage changes, please contact your insurance agency:
          {agencyData()}
        </Typography>
      )
    },
    {
      question: 'Who is my insurance agency?',
      answer: (
        <Typography id="help-collapse-text3" type="paragraph" component="span">
          {agencyData(true, true)}
        </Typography>
      )
    },
    {
      question: 'Who do I contact for assistance with my online account?',
      answer: (
        <div id="help-collapse-text4">
          <Typography type="paragraph" component="span">
            {`If you need help accessing your account. Please call customer service at `}
          </Typography>
          <Link
            type="primary"
            href={`tel:${customerCareNumber}`}
            target="_blank"
          >
            {customerCareNumber}
          </Link>
        </div>
      )
    }
  ];
  const dataClaims = [
    {
      isCategory: true,
      title: 'Claims'
    },
    {
      question: 'How should I notify Slide after an accident or loss?',
      answer: (
        <div id="help-collapse-text7">
          <Typography type="paragraph" component="span">
            {`Immediately notify us by calling the Slide Claims team: `}
          </Typography>
          <Link type="primary" href="tel:(866) 230-3758">
            (866) 230-3758
          </Link>
        </div>
      )
    },
    {
      question: 'What information do I need to file a claim?',
      answer: (
        <div id="help-collapse-text8">
          <Typography type="paragraph">
            When contacting Slide Insurance about a claim, be ready to provide:
          </Typography>
          <ul>
            <li>
              <Typography type="paragraph">
                Your name, address, and phone number.
              </Typography>
            </li>
            <li>
              <Typography type="paragraph">
                Name and addresses of witnesses or injured persons (if
                applicable).
              </Typography>
            </li>
            <li>
              <Typography type="paragraph">
                General loss information including date and time of loss, type
                of loss, etc.
              </Typography>
            </li>
            <li>
              <Typography type="paragraph">
                Your policy number: {policy.policyNumber}.
              </Typography>
            </li>
          </ul>
        </div>
      )
    },
    {
      question: 'What happens after I report a claim?',
      answer: (
        <ul style={{ marginTop: 0 }} id="help-collapse-text9">
          <li>
            <Typography type="paragraph">
              A Slide claims professional will review your report and start the
              claims process.
            </Typography>
          </li>
          <li>
            <Typography type="paragraph">
              We will assign a claim number for tracking purposes.
            </Typography>
          </li>
          <li>
            <Typography type="paragraph">
              We will assign the claim to an adjuster who will contact you and
              answer any questions you may have.
            </Typography>
          </li>
          <li>
            <Typography type="paragraph">
              Depending on the type of claim and your needs, we may offer
              emergency services from one of our approved vendors.
            </Typography>
          </li>
        </ul>
      )
    }
  ];
  const dataPayments = [
    {
      isCategory: true,
      title: 'Payments'
    },
    {
      question: 'What types of payment does Slide accept?',
      answer: (
        <div id="help-collapse-text13">
          <Typography type="paragraph">
            Payments can be made via e-Check, check, or credit card.
          </Typography>
          <br />
          <Typography type="paragraph">
            Check payments should include the remittance slip or policy number
            and should be sent to:
          </Typography>
          <br />
          <Typography type="paragraph">P.O Box 15072</Typography>
          <Typography type="paragraph">Worchester, MA 01615</Typography>
        </div>
      )
    },
    {
      question: 'Does Slide have Auto Pay?',
      answer: (
        <div id="help-collapse-text14">
          <Typography type="paragraph">
            Slide does not currently offer Auto Pay at this time.
          </Typography>
        </div>
      )
    },
    {
      question: 'What payment plan options are available?',
      answer: (
        <div id="help-collapse-text15">
          <Typography type="paragraph" component="span">
            Slide offers homeowners Full Pay, 2 Pay, and 4 Pay plan options. To
            make changes to your payment plan option, please contact your agent.
          </Typography>
        </div>
      )
    },
    {
      question: 'How does Slide handle late payments?',
      answer: (
        <div id="help-collapse-text16">
          <Typography type="paragraph">
            Grace periods and guidelines can vary based on your state and
            payment plan. Please contact your agent or Slide customer service
            for assistance.
          </Typography>
          <br />
          <Typography type="paragraph" component="span">
            You can contact your insurance agent:
            {agencyData()}
          </Typography>
          <br />
          <Typography type="paragraph" component="span">
            You can also contact Slide Customer Care:
            {customerCare}
          </Typography>
        </div>
      )
    }
  ];
  if (!selectedPolicy && ((policies && policies.length > 0) || !policies)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loading && <Spinner size={80} />}
      </div>
    );
  }
  const renderList = (data, title) => {
    return (
      <>
        <Typography
          withTab
          id={`help-collapse-panel${title}`}
          type="heading2"
          component="h2"
          style={styles.category}
        >
          {title}
        </Typography>
        <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
          {data.map(
            (faq, index) =>
              !faq.isCategory && (
                <li key={index}>
                  <ExpansionPanel
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    id={`collapse-button-${index}`}
                    header={
                      <Typography
                        type="caption1"
                        component="h3"
                        style={{ fontWeight: 600 }}
                        htmlFor={`collapse-button-${index}`}
                      >
                        {faq.question}
                      </Typography>
                    }
                  >
                    {faq.answer}
                  </ExpansionPanel>
                </li>
              )
          )}
        </ul>
      </>
    );
  };

  return (
    <>
      <div>
        <Typography withTab id="help-heading" type="heading1" component="h1">
          Frequently Asked Questions
        </Typography>
        <Typography
          withTab
          type="subtitle"
          component="p"
          style={{ fontWeight: 600 }}
        >
          We are happy to help!
        </Typography>
      </div>
      <div style={styles.container}>
        {!selectedPolicy && policies && policies.length === 0 ? (
          <div>
            <Typography withTab type="heading3" component="h4">
              You have no in force policies with Slide Insurance at this time.
              Please contact the Client Experience Center for assistance:
              <div>
                <a href={`tel:800-748-2030`} className="phone" tabIndex="0">
                  (800) 748-2030
                </a>
              </div>
            </Typography>
          </div>
        ) : (
          <>
            {renderList(dataPolicies, 'Policies')}
            {renderList(dataClaims, 'Claims')}
            {renderList(dataPayments, 'Payments')}
          </>
        )}
      </div>
    </>
  );
};

export default Help;
