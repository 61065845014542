import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Tooltip,
  Button,
  useForm,
  Switch
} from 'components/alf-design';
import { useResponsive } from 'utils';
import { useStorage } from 'utils';

import PreferredEmail from '../PreferredEmail';
import Discount from './Discount';
import Warning from './Warning';
import './PaperlessDiscount.css';

const PaperlessDiscount = ({
  paperless,
  preferredEmail,
  policyNumber,
  pendingEndorsement
}) => {
  const { width } = useResponsive();
  const normalWidth = width > 1550 || (width < 1280 && width > 620);

  const [originalPaperless, setOriginalPaperless] = useStorage(
    policyNumber,
    paperless
  );
  const [isPaperless, setIsPaperless] = useState(originalPaperless);
  const [type, setType] = useState('Add');
  const [showPreferred, togglePreferred] = useState(false);
  const [showDiscount, toggleDiscount] = useState(false);
  const [showWarning, toggleWarning] = useState(false);
  const { handleChange, values, setFormValues } = useForm(
    {
      paperless: originalPaperless
    },
    () => {}
  );

  useEffect(() => {
    if (!isPaperless && values?.paperless) {
      if (!preferredEmail) {
        setType('Add Paperless');
      } else {
        setType('Confirm');
      }
      togglePreferred(true);
      setIsPaperless(true);
    }

    if (isPaperless && !values?.paperless) {
      toggleWarning(true);
      setFormValues({ paperless: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.paperless]);

  const preferredEmailClose = (success) => {
    if (success) {
      setOriginalPaperless(true);
      setIsPaperless(true);
      values.paperless = true;
    }
    if (isPaperless && !paperless && !originalPaperless && !success) {
      setIsPaperless(false);
      values.paperless = false;
    }
    togglePreferred(false);
  };

  const handleUpdate = () => {
    if (isPaperless) {
      setType('Update');
    } else {
      setType('Paperless');
    }
    togglePreferred(true);
  };

  return (
    <div>
      <dl style={{ marginBottom: 10, marginTop: normalWidth ? 16 : 0 }}>
        <dt>
          <Typography type={'caption1'} component="span">
            <b>Paperless Discount</b>
            <div className="paperless__div_discount">
              <Tooltip
                display="block"
                ariaLabel="Paperless Discount tooltip"
                className="paperless-tooltip"
                dataFor="paperless"
                clickable={true}
                text={
                  <div>
                    By enrolling in electronic delivery of policy and billing
                    documents, you affirm you have read the acknowledgment of
                    consent, confirming your ability to download and view
                    documents via the email that is listed on your policy
                    documents.
                    <br />
                    <br />
                    <Typography
                      className="paperless__discountButton"
                      onClick={() => toggleDiscount(true)}
                      withTab
                    >
                      Click here to view full acknowledgment of consent.
                    </Typography>
                  </div>
                }
              />
            </div>
          </Typography>
        </dt>
        <dt className="paperless__dt_switch">
          <Typography
            type={'caption1'}
            component="span"
            style={{ marginTop: 10 }}
          >
            This policy is enrolled in paperless billing and documents.
          </Typography>
          <Switch
            id="paperless"
            name="paperless"
            containerClassName="flex-col justify-center max-w-sm mx-auto text-center text-bold"
            handleChange={handleChange}
            values={values?.paperless}
            showYesNo={false}
            disabled={pendingEndorsement}
          />
        </dt>
        {preferredEmail ? (
          <dt style={{ marginTop: 30 }}>
            <Typography type={'caption1'} component="span">
              <b>Your Preferred Email</b>
            </Typography>
            <div className="paperless__div_email">
              <Typography className="paperless__email" withTab>
                {preferredEmail}
              </Typography>
              {pendingEndorsement ? (
                <Typography className="paperless__pendingEmailUpdate" withTab>
                  Pending Email Update
                </Typography>
              ) : (
                <Typography
                  className="paperless__updateEmail"
                  onClick={handleUpdate}
                  withTab
                >
                  Update Email
                </Typography>
              )}
            </div>
          </dt>
        ) : (
          <dt className="paperless__dt_button">
            <Button
              id="payment-history-button"
              onClick={() => {
                setType('Add');
                togglePreferred(true);
              }}
              fullWidth={!normalWidth}
              style={!normalWidth ? { marginTop: 10 } : null}
            >
              Add Preferred Email
            </Button>
          </dt>
        )}
      </dl>

      <PreferredEmail
        show={showPreferred}
        onClose={preferredEmailClose}
        policyNumber={policyNumber}
        type={type}
        paperless={isPaperless}
      />

      <Discount show={showDiscount} onClose={() => toggleDiscount(false)} />
      <Warning show={showWarning} onClose={() => toggleWarning(false)} />
    </div>
  );
};

PaperlessDiscount.propTypes = {
  paperless: PropTypes.bool,
  preferredEmail: PropTypes.string,
  policyNumber: PropTypes.string,
  pendingEndorsement: PropTypes.bool
};

export default PaperlessDiscount;
