/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { logo } from 'assets/img';
import {
  withApi,
  customTypes,
  useResponsive,
  validate as externalValidator,
  useAuth0
} from 'utils';

import {
  Row,
  Col,
  Input,
  Form,
  FormField,
  CardContainer,
  Button,
  Typography,
  PasswordCheck,
  Dialog,
  useForm,
  Tooltip
} from '../alf-design';
import Guidance from '../Guidance';
import config from '../../config';

const styles = {
  fields: {
    margin: '32px 0'
  },
  loginLink: {
    fontfamily: 'comic',
    marginTop: '24px',
    cursor: 'pointer',
    textAlign: 'center'
  },
  underlineStyle: {
    color: '#6648DC',
    paddingBottom: 3,
    borderWidth: '0 0 1px 0',
    borderColor: '#6648DC',
    borderStyle: 'solid'
  },
  errorPolicy: {
    background: '#FEF7F6 0% 0% no-repeat padding-box',
    padding: 12,
    fontSize: 'min(16rem, calc(16px * 1.5))',
    color: '#DC2A2A',
    marginBottom: 16
  },
  buttonRow: {
    flexDirection: 'row',
    display: 'flex'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  imgContainer: {
    padding: 50,
    display: 'flex',
    justifyContent: 'center'
  },
  img: {
    width: 162,
    height: 35,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
};

const initState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  policyNumber: '',
  zip: ''
};

const isPasswordValid = (password) => {
  if (!password) {
    return false;
  }
  const characterLengthRule = password.length >= 8 && password.length <= 64;
  const uppercaseCharacterRule = /^.*[A-Z].*$/.test(password) ? 1 : 0;
  const lowercaseCharacterRule = /^.*[a-z].*$/.test(password) ? 1 : 0;
  const numberRule = /^.*[0-9].*$/.test(password) ? 1 : 0;
  const symbolRule = /^.*[^0-9a-zA-Z].*$/.test(password) ? 1 : 0;

  const atLeast3True =
    uppercaseCharacterRule + lowercaseCharacterRule + numberRule + symbolRule >=
    3;
  return characterLengthRule && atLeast3True;
};

function validate(values) {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last name is required';
  }
  if (!/.+@.+\..+/.test(values.email)) {
    errors.email = 'Please provide a valid email address';
  }

  if (values.policyNumber) {
    const beginning = values.policyNumber.slice(0, 2);
    if (
      beginning !== 'H3' &&
      beginning !== 'H6' &&
      beginning !== 'D3' &&
      beginning !== 'h3' &&
      beginning !== 'h6' &&
      beginning !== 'd3'
    ) {
      errors.policyNumber =
        'Only policies beginning with H3, H6 or D3 can create an account at this time';
    } else {
      if (!externalValidator.policyNumber(values.policyNumber)) {
        errors.policyNumber = 'Invalid policy number';
      }
    }
  }

  if (!values.zip || !/^\d{5}$/.test(values.zip)) {
    errors.zip = 'Invalid zip';
  }

  if (!values.password) {
    errors.password = 'A password is required';
  } else if (!isPasswordValid(values.password)) {
    errors.password = 'Password is too weak';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Password confirmation is required';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'The passwords do not match';
  } else if (errors.password) {
    errors.confirmPassword = 'Password is too weak';
  }

  return errors;
}

const CreateAccount = ({ api, history }) => {
  const [error, setError] = useState({});
  const [verify, setVerify] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const [disabledGuidance, setDisabledGuidance] = useState(true);
  const { loginWithRedirect } = useAuth0();
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting
  } = useForm(initState, validate, true);
  const { screenIsUp, screenIsDown, width } = useResponsive();
  const showLogo = width < 768;

  const onSubmit = (e) => {
    handleSubmit(e, (callback) => {
      const { email, password, firstName, lastName } = values;
      const policies = [
        { id: 0, policyNumber: values.policyNumber, zip: values.zip }
      ];
      const headers = {
        client_id: config.mulesoft.clientId,
        client_secret: config.mulesoft.clientSecret
      };
      api
        .post(
          '/identity/sign-up',
          {
            email,
            password,
            policies,
            firstName,
            lastName
          },
          { headers }
        )
        .then(() => {
          setVerify(true);
          callback(true);
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          callback(err);
          if (err.response) {
            if (err.response.status === 404) {
              setError({
                number: 404,
                description:
                  'Policy entered was not found. Please contact your agent for assistance.'
              });
            } else if (err.response.status === 400) {
              setError({
                number: 400,
                description:
                  'Uh-Oh! The policy information entered does not match our records.'
              });
            } else {
              setError({
                number: err.response.status,
                description: err.response.data.Errors[0].value
              });
            }
          } else if (err.request) {
            setError({
              number: null,
              description:
                'Network error. Please check your connection and try again.'
            });
          } else {
            setError({
              number: null,
              description: 'Error attempting to create new user account.'
            });
          }
        });
    });
  };

  const handleCancel = () => {
    history.push('/');
  };

  const handleLogin = async () => {
    await loginWithRedirect();
    history.replace('/');
  };

  const isEmpty = (input) =>
    !Object.values(input).some((i) => i !== null && i !== '');

  useEffect(() => {
    document.title =
      'Create an Account | Online Policy Access for Slide Insurance Policyholders';
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        'content',
        'Create an account and verify your policy to gain online access to your Slide policy. Make payments, view your policy details, access your agent info, and more!'
      );
    }
  }, []);

  return (
    <CardContainer>
      {showLogo && (
        <div style={styles.imgContainer}>
          <img style={styles.img} src={logo} alt="Logo" />
        </div>
      )}
      <Typography type="heading3" component="h1">
        Create an Account
      </Typography>

      {error.description && (
        <Typography style={styles.errorPolicy}>{error.description}</Typography>
      )}
      <Form id="form" onSubmit={onSubmit}>
        <div style={styles.fields}>
          <Row style={{ marginTop: 24 }}>
            <Col>
              <FormField
                label="Email Address"
                error={touched.email ? errors.email : ''}
                icon={
                  <Tooltip
                    ariaLabel="Email Tooltip"
                    text="NOTE: This email is used for account access only. Policy correspondence will be directed to the email address found on your policy declaration page."
                  />
                }
              >
                <Input
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  id="email-input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={!!errors.email && !!touched.email}
                  success={!errors.email && !!touched.email}
                />
              </FormField>
            </Col>
            <Col>
              <FormField
                label="Password"
                error={touched.password ? errors.password : ''}
                labelFor="password-input"
              >
                <PasswordCheck
                  password={values.password}
                  setPasswordValid={setPasswordValid}
                  isPopover={screenIsUp('lg')}
                >
                  <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                    id="password-input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={
                      !passwordValid &&
                      (!!touched.password || !!touched.confirmPassword)
                    }
                    success={passwordValid && !!touched.password}
                  />
                </PasswordCheck>
              </FormField>
            </Col>
            <Col>
              <FormField
                label="Confirm Password"
                error={touched.confirmPassword ? errors.confirmPassword : ''}
              >
                <Input
                  placeholder="Password"
                  name="confirmPassword"
                  type="password"
                  id="confirm-password-input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  error={!!errors.confirmPassword && !!touched.confirmPassword}
                  success={!errors.confirmPassword && !!touched.confirmPassword}
                />
              </FormField>
            </Col>
          </Row>
        </div>
        <Row gutter={8}>
          <Typography component="h2" type="heading3">
            Verify Your Existing Policy
          </Typography>
        </Row>
        <Row gutter={24}>
          <Col>
            <FormField
              label="First Name"
              error={touched.firstName ? errors.firstName : ''}
            >
              <Input
                placeholder="First name of the primary policyholder"
                name="firstName"
                id="first-name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                error={
                  (!!errors.firstName && !!touched.firstName) ||
                  error.number === 400
                }
                success={!errors.firstName && !!touched.firstName}
              />
            </FormField>
          </Col>
          <Col>
            <FormField
              label="Last Name"
              error={touched.lastName ? errors.lastName : ''}
            >
              <Input
                placeholder="Last name of the primary policyholder"
                name="lastName"
                id="last-name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                error={
                  (!!errors.lastName && !!touched.lastName) ||
                  error.number === 400
                }
                success={!errors.lastName && !!touched.lastName}
              />
            </FormField>
          </Col>
          <Col>
            <FormField
              label="Property Zip Code"
              error={
                touched.zip && (errors.zip || disabledGuidance)
                  ? 'Invalid property zip code'
                  : ''
              }
            >
              <Input
                id="zip-input"
                placeholder="Zip Code"
                name="zip"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zip}
                error={
                  (!!disabledGuidance && !!touched.zip) ||
                  error.number === 400 ||
                  (!!errors.zip && !!touched.zip)
                }
                success={!errors.zip && !disabledGuidance && !!touched.zip}
              />
            </FormField>
          </Col>
          <Col>
            <FormField
              label="Policy Number"
              error={touched.policyNumber ? errors.policyNumber : ''}
              icon={
                <Guidance
                  zip={values.zip}
                  disabledGuidance={disabledGuidance || errors.zip}
                  setDisabledGuidance={setDisabledGuidance}
                />
              }
            >
              <Input
                placeholder={
                  errors.zip || disabledGuidance || !touched.zip
                    ? 'Insert Zip Code First'
                    : 'Policy Number'
                }
                name="policyNumber"
                type="text"
                id="policy-input"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.policyNumber}
                error={
                  (!!errors.policyNumber && !!touched.policyNumber) ||
                  error.number === 400
                }
                success={!errors.policyNumber && !!touched.policyNumber}
                disabled={!!disabledGuidance || errors.zip}
              />
            </FormField>
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{
            ...styles.buttonRow,
            flexFlow: screenIsDown('sm') ? 'row wrap-reverse' : 'row'
          }}
        >
          <Col
            xs={24}
            sm={12}
            style={{
              ...styles.buttonContainer,
              maxWidth: screenIsDown('md') ? '100%' : '203px'
            }}
          >
            <Button
              id="signup-button-cancel"
              // type="secondary"
              htmlType="button"
              onClick={handleCancel}
              style={{
                padding: screenIsDown('sm') ? '10px 20px 0' : '8px 20px 0'
              }}
              fullWidth
              link
            >
              Cancel
            </Button>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={0}
            style={{
              ...styles.buttonContainer,
              marginBottom: screenIsDown('sm') ? 16 : 0,
              maxWidth: screenIsDown('md') ? '100%' : '203px'
            }}
          >
            <Button
              id="signup-button-next"
              withLoading={isSubmitting}
              type="primary"
              htmlType="submit"
              disabled={isSubmitting || isEmpty(values) || !isEmpty(errors)}
              fullWidth
            >
              Next
            </Button>
          </Col>
        </Row>

        <div
          onClick={handleLogin}
          style={styles.loginLink}
          onKeyPress={handleLogin}
          role="link"
          tabIndex={0}
        >
          <Typography
            type="caption1"
            component="span"
            style={styles.underlineStyle}
          >
            Already have an account? <b>Log In</b>
          </Typography>
        </div>
      </Form>
      <Dialog
        show={verify}
        onClose={handleLogin}
        actions={
          <Button
            type="primary"
            style={{ margin: '0 auto' }}
            onClick={handleLogin}
            link
          >
            Back to Log In
          </Button>
        }
      >
        <div style={{ padding: 24, maxWidth: '480px' }}>
          <Typography
            style={{ color: '#6648DC', textAlign: 'center' }}
            type="paragraph"
          >
            A verification email has been sent to your email address. Please
            verify your account before you log in.
          </Typography>
        </div>
      </Dialog>
    </CardContainer>
  );
};

CreateAccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
    replace: PropTypes.func
  }),
  api: customTypes.api
};
export default withApi(CreateAccount);
