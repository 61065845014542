export default (url, formData) => {
  const form = document.createElement('form');
  form.action = url;
  form.method = 'post';

  Object.keys(formData).forEach((key) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.id = key;
    input.name = key;
    input.value = formData[key];
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
};
