/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useResponsive, usePolicy, useFlags } from 'utils';

import { Card, Typography, Tabs, Button, Row, Link } from '../alf-design';
import ClaimDetailsHeader from './ClaimDetailsHeader';
import WithoutClaims from './WithoutClaims';
import ClaimDetails from './ClaimDetails';
import './Claims.css';

const Claims = ({ claims }) => {
  const { disableClaimSubmission } = useFlags();

  const { selectedPolicy } = usePolicy();
  const [expanded, setExpanded] = useState(0);
  const { screenIsDown } = useResponsive();
  const handleChange = (panel) => (newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getArrayOfDates = (obj) => {
    const res = [];
    const dates = [
      'closeDate',
      'inspectionCompleteDate',
      'reviewCompleteDate',
      'underReviewDate',
      'createdDate'
    ];
    // eslint-disable-next-line
    for (const date of dates) {
      if (obj[date] && moment(obj[date]).isValid()) {
        res.push(moment(obj[date]));
      }
    }
    return res;
  };
  const isActivity = (claim) => claim.stage.toUpperCase() !== 'OPEN';

  const sortClaims = (a, b) => {
    if (a.status === 'Open' && b.status !== 'Open') {
      return -1;
    }
    if (a.status !== 'Open' && b.status === 'Open') {
      return 1;
    }
    if (!isActivity(a) && isActivity(b)) {
      return -1;
    }
    if (!isActivity(b) && isActivity(a)) {
      return 1;
    }
    const aDates = getArrayOfDates(a);
    const bDates = getArrayOfDates(b);

    if (!isActivity(a) && !isActivity(b)) {
      if (aDates[0].isBefore(bDates[0])) {
        return 1;
      }
      if (bDates[0].isBefore(aDates[0])) {
        return -1;
      }
      return 0;
    }

    const aMaxDate = moment.max(aDates);
    const bMaxDate = moment.max(bDates);

    if (aMaxDate.isBefore(bMaxDate)) {
      return 1;
    }
    if (bMaxDate.isBefore(aMaxDate)) {
      return -1;
    }
    return 0;
  };

  const goToSubmission = () => {
    window.open(selectedPolicy.fnol.data.url);
  };

  return (
    <>
      <div className="alf-claims-root">
        <Card>
          {/*TODO: HOLD OFF until the final mockup from Marketing
          <div style={{ display: 'flex' }}>
            <Typography type="heading3" component="h3">
              Claims Information -
            </Typography>
            <Typography
              type="heading3"
              style={{ color: '#34D399', paddingLeft: 8 }}
            >
              Coming Soon!
            </Typography>
            {screenIsDown('lg') &&
              claims.length !== 0 &&
              !disableClaimSubmission && (
                <Button
                  id="dashboard-button-submit-claim"
                  style={{ marginBottom: 16 }}
                  type="secondary"
                  onClick={goToSubmission}
                  whiteBorder
                >
                  Submit a Claim
                </Button>
              )}
          </div>
          */}
          <div style={{ width: 'fit-content' }}>
            <Typography type="heading3" component="h3">
              Report a Loss
            </Typography>
            <Typography type="paragraph">
              {`To file a claim, click the button below or call Claims Services at `}
              <a
                href={`tel:866-230-3758`}
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
                tabIndex="0"
              >
                (866) 230-3758.
              </a>
              <br />
              Please have your policy number ready for identification.
            </Typography>
            <Button
              id="Report-Loss-button"
              type="primary"
              onClick={() =>
                window.open('https://slide.liberate.claims/fnol-prod')
              }
              style={{ width: 180, marginTop: 20 }}
              link
            >
              File a Claim
            </Button>
          </div>
        </Card>
        {/*TODO: HOLD OFF until the final mockup from Marketing 
        {claims.length === 0 || !claims ? (
          <WithoutClaims
            goToSubmission={goToSubmission}
            disableClaimSubmission={disableClaimSubmission}
          />
        ) : (
          <>
            {screenIsDown('lg') ? (
              <Card className="alf-claims-card">
                {claims.map((claim, index) => (
                  <ClaimDetails
                    key={claim.claimNumber}
                    claim={claim}
                    index={index}
                    expanded={expanded}
                    handleChange={handleChange}
                  />
                ))}
              </Card>
            ) : (
              <>
                <Tabs
                  setExpanded={setExpanded}
                  expanded={expanded}
                  button={
                    !disableClaimSubmission && (
                      <Button
                        id="dashboard-button-submit-claim"
                        onClick={goToSubmission}
                        whiteBorder
                        style={{ float: 'right' }}
                      >
                        Submit a Claim
                      </Button>
                    )
                  }
                >
                  {claims.sort(sortClaims).map((claim, index) => (
                    <div
                      key={claim.claimNumber}
                      header={<ClaimDetailsHeader claim={claim} />}
                    >
                      <ClaimDetails
                        claim={claim}
                        index={index}
                        handleChange={handleChange}
                        expanded={expanded}
                      />
                    </div>
                  ))}
                </Tabs>
              </>
            )}
          </>
        )} */}
      </div>
    </>
  );
};

Claims.propTypes = {
  claims: PropTypes.arrayOf(PropTypes.shape({}))
};
export default withRouter(Claims);
