import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Row, Col, Tooltip } from 'components/alf-design';
import { STATUS, METHOD } from 'utils/constants';

import BillingContentNonDC from './BillingContentNonDC';
import PolicyCancelled from './PolicyCancelled';
import BillingContentDC from './BillingContentDC';

const Billing = ({
  policy: {
    agent,
    statusCode,
    billing,
    policyNumber,
    policySystem,
    insured,
    pendingEndorsement
  },
  handleHistoryClick,
  handleMakePaymentClick,
  loading
}) => {
  if (statusCode === STATUS.CANCELLED) {
    return (
      <PolicyCancelled
        agent={agent}
        handleHistoryClick={handleHistoryClick}
        isDuckCreek={billing !== null}
      />
    );
  }

  let tooltip = 'To update your payment plan, please contact your agent.';
  if (policySystem === 'Duck Creek' && billing && billing.autoPay) {
    switch (billing.collectionMethod) {
      case METHOD.CARD:
      case METHOD.PENDING_CARD:
        tooltip =
          'To update your payment plan or pay with your bank, please contact your agent.';
        break;
      case METHOD.BANK:
      case METHOD.PENDING_BANK:
        tooltip =
          'To update your payment plan or pay with your card, please contact your agent.';
        break;
      default:
        tooltip = 'To update your payment plan, please contact your agent.';
        break;
    }
  }

  return (
    <Card style={{ flexGrow: 1 }}>
      <Typography type="heading3" component="h3">
        Billing
        {policySystem === 'Duck Creek' && billing && billing.autoPay && (
          <Tooltip ariaLabel="Billing Card Tooltip" text={tooltip} />
        )}
      </Typography>
      {billing === null && (
        <>
          <Typography
            type="caption1"
            component="span"
            style={{
              display: 'inline-block',
              color: '#DC2A2A',
              marginTop: 10,
              marginBottom: 20
            }}
          >
            Something went wrong.
          </Typography>
          <Typography type="paragraph">
            If you need immediate assistance, please contact Customer Service at{' '}
            <a
              href="tel:8007482030"
              style={{ color: '#6648DC', textDecoration: 'none' }}
            >
              (800) 748-2030
            </a>
            .
          </Typography>
        </>
      )}
      {billing && policySystem === 'Duck Creek' && (
        <BillingContentDC
          agent={agent}
          billing={billing}
          insuredEmail={insured.email}
          policyNumber={policyNumber}
          handleHistoryClick={handleHistoryClick}
          handleMakePaymentClick={handleMakePaymentClick}
          policyStatus={statusCode}
          pendingEndorsement={pendingEndorsement}
          loading={loading}
        />
      )}
      {billing && policySystem !== 'Duck Creek' && (
        <Row className="billing-content">
          <Col>
            <BillingContentNonDC
              agent={agent}
              policyNumber={policyNumber}
              policyStatus={statusCode}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};
Billing.propTypes = {
  policy: PropTypes.object,
  handleHistoryClick: PropTypes.func,
  handleMakePaymentClick: PropTypes.func
};

export default Billing;
