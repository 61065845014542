export const XS = 0;
export const SM = 425;
export const MD = 768;
export const LG = 1280;
export const XL = 1920;

export const POLICY_SYSTEM_AGENT_INTERACT = 'CO';
export const POLICY_SYSTEM_AGENCY_LINK = 'Agency Link';
export const POLICY_SYSTEM_MAJESCO = 'MJ';
export const POLICY_SYSTEM_BLUE_CODE = 'Blue Code';

export const STATUS = {
  ACTIVE: 'Active',
  PENDING_CANCEL: 'Pending Cancellation',
  CANCELLED: 'Cancelled'
};

export const METHOD = {
  CARD: 'Card',
  BANK: 'Bank',
  PAPER: 'Paper',
  PENDING_CARD: 'Pending Card',
  PENDING_BANK: 'Pending Bank'
};
