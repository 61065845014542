export default {
  appUrl: process.env.REACT_APP_URL,
  apiUrl: process.env.REACT_APP_MULESOFT_API_URL,
  claimSubmissionUrl: window.env.CLAIM_SUBMISSION_URL,
  auth0: {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN
  },
  sessionTimeout: window.env.SESSION_TIMEOUT,
  launchDarkly: {
    clientId: window.env.LAUNCHDARKLY_CLIENT_ID
  },
  mulesoft: {
    clientId: process.env.REACT_APP_MULESOFT_CLIENT_ID,
    clientSecret: process.env.REACT_APP_MULESOFT_CLIENT_SECRET
  },

  portalOneAuthenticationKey: process.env.REACT_APP_ONEINC_KEY,
  portalOneApiUrl: process.env.REACT_APP_ONEINC_URL,
  oneIncWebhook: process.env.REACT_APP_ONEINC_WEBHOOK,
  mockPolicies: process.env.REACT_APP_MOCK_POLICIES,
  datadog: {
    env: process.env.REACT_APP_DD_ENV,
    id: process.env.REACT_APP_DD_ID,
    secret: process.env.REACT_APP_DD_TOKEN
  },
  banner: {
    display: process.env.REACT_APP_BANNER_DISPLAY,
    msg: process.env.REACT_APP_BANNER_MSG
  },
  maintenancePage: process.env.REACT_APP_MAINTENANCE_PAGE
};
