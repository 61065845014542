import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Link,
  Spinner,
  showErrorAlert
} from 'components/alf-design';
import { formats, withApi } from 'utils';
import { STATUS } from 'utils/constants';

const styles = {
  circularProgress: {
    display: 'flex',
    justifyContent: 'center'
  }
};
const BillingContentNonDC = ({ agent, api, policyNumber, policyStatus }) => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const errorDescription = (error, request) => {
    let description;
    if (error.response) {
      description = error.response.data.error;
    } else if (error.request) {
      description =
        'Network error. Please check your connection and try again.';
    } else {
      description = `Error attempting to fetch your ${request}. Refresh page to try again.`;
    }
    return description;
  };
  const getURL = () => {
    setLoading(true);
    api.withAuth
      .getPaymentMethod(policyNumber)
      .then((res) => {
        setUrl(res.data.data.url);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        showErrorAlert({
          message: 'Billing Error',
          description: errorDescription(err, 'URL for payment')
        });
      });
  };
  useEffect(() => {
    getURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyNumber]);

  if (loading) {
    return (
      <div style={styles.circularProgress}>
        <Spinner id="dashboard-spinner" size={80} />
      </div>
    );
  }

  return url ? (
    <>
      {policyStatus === STATUS.PENDING_CANCEL && (
        <Typography
          type="caption1"
          component="span"
          style={{
            display: 'inline-block',
            color: '#DC2A2A'
          }}
        >
          This policy is pending cancellation. For billing information, please
          contact your agent.
        </Typography>
      )}
      <Typography type="caption1" component="p">
        Click the button below to manage your payments.
      </Typography>
      <Link>
        <Button
          onClick={handleClick}
          type="primary"
          style={{ width: 220, marginTop: 26 }}
          link
        >
          Payment Portal
        </Button>
      </Link>
    </>
  ) : (
    <>
      <Typography type="caption1" component="p">
        To manage your payments, please contact your agent.
      </Typography>
      <Typography type="paragraph" component="p" style={{ marginTop: 26 }}>
        <b>{`${agent.firstName} ${agent.lastName}`}</b>
      </Typography>
      <Link type="primary" href={`tel:${agent.phone}`}>
        {formats.phoneFormat(agent.phone)}
      </Link>
    </>
  );
};

BillingContentNonDC.propTypes = {
  agent: PropTypes.object,
  policyNumber: PropTypes.string
};

export default withApi(BillingContentNonDC);
