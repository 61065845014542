import React from 'react';
import config from 'config';

import { Typography, Row } from '../alf-design';

const styles = {
  paragraph: {
    textWrap: 'balance',
    padding: 5
  },
  row: {
    backgroundColor: '#E0E7FF',
    paddingBottom: 13,
    paddingTop: 13,
    textAlign: 'center',
    margin: 0
  },
  title: {
    color: '#6647dc'
  }
};

const Banner = () => (
  <Row gutter={24} style={styles.row}>
    <Typography type="paragraph" style={styles.paragraph}>
      <b style={styles.title}>System Maintenance: </b>
      {config?.banner?.msg}
    </Typography>
  </Row>
);

export default Banner;
