import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import PropTypes from 'prop-types';

import { useApi } from './withApi';
import transform from './transform';

const PolicyContext = React.createContext({});

export const usePolicy = (selectedPolicyNumber, isAdmin, options = {}) => {
  const context = useContext(PolicyContext);

  const {
    fetchPolicy,
    selectedPolicy,
    setLoading,
    fetchPolicies,
    policies,
    claims,
    initialLoad,
    setInitialLoad
  } = context;
  const { onError, loadPolicies } = options;

  useEffect(() => {
    if (loadPolicies && !policies) {
      setInitialLoad(true);
      setLoading(true);
      fetchPolicies(onError, isAdmin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies]);
  useEffect(() => {
    if (initialLoad && policies && selectedPolicy && claims) {
      setInitialLoad(false);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claims, initialLoad, policies, selectedPolicy]);
  useEffect(() => {
    if (
      selectedPolicyNumber &&
      (!selectedPolicy || selectedPolicy.policyNumber !== selectedPolicyNumber)
    ) {
      if (!initialLoad) {
        setInitialLoad(true);
      }
      fetchPolicy(selectedPolicyNumber, onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolicyNumber]);

  return context;
};

export const PolicyProvider = ({ children }) => {
  const [policies, setPolicies] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [claims, setClaims] = useState(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const api = useApi();

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const fetchClaims = useCallback(
    (policyNumber, onError) => {
      api.withAuth
        .getClaims(policyNumber)
        .then((res) => {
          const transClaims = transform.claims(res.data);
          setClaims(transClaims);
          if (!initialLoad) {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (onError) {
            onError(err, 'claims');
          }
        });
    },
    [api.withAuth, initialLoad]
  );

  const fetchPolicy = useCallback(
    (policyNumber, onError) => {
      if (!loadingRef.current) {
        setLoading(true);
        setClaims(null);
      }

      api.withAuth
        .getPolicy(policyNumber)
        .then((res) => {
          const transPolicy = transform.policy(res.data);
          setSelectedPolicy(transPolicy);
          fetchClaims(policyNumber, onError);
        })
        .catch((err) => {
          setLoading(false);
          if (onError) {
            onError(err, 'policy');
          }
        });
    },
    [loadingRef, setLoading, setClaims, api, setSelectedPolicy, fetchClaims]
  );

  const fetchPolicies = (onError, isAdmin) => {
    api.withAuth
      .getAllPolicies()
      .then((res) => {
        const policiesMap = res.data.reduce((accumulator, currentObject) => {
          const existingObject = accumulator.find(
            (obj) => obj.policyNumber === currentObject.policyNumber
          );
          if (!existingObject) {
            accumulator.push(currentObject);
          } else if (
            new Date(currentObject.effectiveDate) >
              new Date(existingObject.effectiveDate) &&
            new Date(currentObject.expirationDate) >
              new Date(existingObject.expirationDate)
          ) {
            const index = accumulator.indexOf(existingObject);
            accumulator.splice(index, 1, currentObject);
          }
          return accumulator;
        }, []);

        setPolicies(policiesMap);

        if (res.data.length === 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (onError && !isAdmin) {
          onError(err, 'policies');
        } else {
          setPolicies([]);
        }
      });
  };
  return (
    <PolicyContext.Provider
      value={{
        policies,
        selectedPolicy,
        claims,
        setPolicies,
        setSelectedPolicy,
        setClaims,
        fetchPolicy,
        fetchPolicies,
        loading,
        setLoading,
        initialLoad,
        setInitialLoad
      }}
    >
      {children}
    </PolicyContext.Provider>
  );
};

PolicyProvider.propTypes = {
  children: PropTypes.any
};
